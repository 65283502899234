import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../types/titles';
import VerificationQuestionariesSummaryLayout from '../../../../components/verification/questionaries/summary';

const QuestionariesSummaryVerification = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.VERIFICATION.QUESTIONARIES.SUMMARY}</title>
			</Helmet>
			<VerificationQuestionariesSummaryLayout />
		</>
	);
};

export default QuestionariesSummaryVerification;

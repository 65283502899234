import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import VerificationSumSubLayout from '../../../components/verification/sumsub';

const VerificationSumSub = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.VERIFICATION.SUMSUB}</title>
			</Helmet>
			<VerificationSumSubLayout />
		</>
	);
};

export default VerificationSumSub;

import { createBrowserRouter } from 'react-router-dom';
import { ROUTES } from './types/routes';
import ErrorLayout from './components/error';
import Exchange, { exchangeLoader } from './routes/exchange/exchange.route';
import InActive from './routes/inactive.route';
import Market from './routes/market/market.route';
import Auth from './routes/auth/auth.route';
import LoginLayout from './components/auth/login';
import LoginTwoFactor from './routes/auth/login.twofactor.route';
import LoginOTP from './routes/auth/login.otp.route';
import LoginSelectTwoFa from './routes/auth/login.selectwofa.route';
import {
	CommonRoute,
	PaymentsRoute,
	GlobalRoute,
	ProfileRoute,
	ProtectedRoute,
	ExchangeRoute,
	RegistrationRoute,
	VerificationRoute,
} from './components/route';
import About, { aboutLoader } from './routes/about/about.route';
import LegalStatements, {
	legalStatementsLoader,
} from './routes/about/legal-statements/legal-statements.route';
import Community, { communityLoader } from './routes/about/community/community.route';
import Help, { helpLoader } from './routes/about/help/help.route';
import { globalRouteLoader, registrationLoader } from './utils/loaders';
import Wallet from './routes/wallet/wallet.route';
import LoginSetupTwoFa from './routes/auth/login.setuptwofa.route';
import LoginSecretKey, { secretKeyLoader } from './routes/auth/login.secretkey.route';
import Maintain from './routes/maintain/maintain.route';
import AssetDetails from './routes/market/asset-details.market.route';
import Search from './routes/search';
import Biometry from './routes/auth/login.biometry.route';
import Main from './routes/main/main.route';
import Profile from './routes/profile/profile.route';
import ProfileSettings from './routes/profile/settings.profile.route';
import ProfileMyDocuments from './routes/profile/documents.profile.route';
import ProfileAccountInfo from './routes/profile/account-info.profile.route';
import History, { historyRouteLoader } from './routes/history/history.route';
import Deposit from './routes/deposit/deposit.route';
import OplatiDeposit from './routes/deposit/oplati/oplati.deposit.route';
import CardDeposit from './routes/deposit/card/card.deposit.route';
import BankTransferDeposit, {
	bankTransferLoader,
} from './routes/deposit/bank/bank.deposit.route';
import CryptoDeposit from './routes/deposit/crypto/crypto.deposit.route';
import EripDeposit from './routes/deposit/erip/erip.deposit.route';
import BankTransferInvoiceDeposit from './routes/deposit/bank/invoice/bank.invoice.deposit.route';
import BankTransferInvoiceHistoryDeposit from './routes/deposit/bank/history/bank.history.deposit.route';
import Withdraw from './routes/withdraw/withdraw.route';
import CryptoWithdraw from './routes/withdraw/crypto/crypto.withdraw.route';
import CryptoWithdrawConfirmation from './routes/withdraw/crypto/confirmation/confirmation.crypto.withdraw.route';
import CryptoWithdrawSummary from './routes/withdraw/crypto/summary/summary.crypto.withdraw.route';
import CardWithdraw from './routes/withdraw/card/card.withdraw.crypto';
import CardWithdrawConfirmation from './routes/withdraw/card/confirmation/confirmation.card.withdraw.route';
import CardWithdrawSummary from './routes/withdraw/card/summary/summary.card.withdraw.route';
import SavedAddresses from './routes/profile/saved-addresses.profile.route';
import BankWithdraw from './routes/withdraw/bank/bank.withdraw.route';
import BankWithdrawConfirmation from './routes/withdraw/bank/confirmation/confirmation.bank.withdraw.route';
import BankWithdrawSummary from './routes/withdraw/bank/summary/summary.bank.withdraw.route';
import ExchangeConfirmation from './routes/exchange/confirmation/exchange.confirmation.route';
import ExchangeSummary from './routes/exchange/summary/exchange.summary.route';
import PasswordRecovery from './routes/auth/recovery/recovery.route';
import PasswordRecoveryOTP from './routes/auth/recovery/otp/recovery.otp.route';
import PasswordRecoveryChangePassword from './routes/auth/recovery/change-password/recovery.change-password.route';
import ProfileSecurity from './routes/profile/security/security.profile.route';
import ProfileSecurityChangePassword from './routes/profile/security/change-password/change-password.security.profile.route';
import ProfileSecurityTwoFactor from './routes/profile/security/change-password/two-factor/security.two-factor.profile.route';
import ProfileSecuritySelectTwoFactor from './routes/profile/security/change-password/select-two-factor/security.select-two-factor.profile.route';
import ProfileSecurityOTP from './routes/profile/security/change-password/otp/security.otp.profile.route';
import WithdrawTwoFa from './routes/withdraw/twofa/twofa/withdraw.twofa.route';
import WithdrawSelectTwoFa from './routes/withdraw/twofa/select-two-factor/withdraw.select-two-factor.route';
import WithdrawOTP from './routes/withdraw/twofa/otp/withdraw.otp.route';
import TwoFaSettingsProfile from './routes/profile/security/2fa-settings/2fa-settings.profile.route';
import TwoFaSettingsOTPProfile from './routes/profile/security/2fa-settings/otp/2fa-settings.otp.profile.route';
import TwoFaSettingsSetupProfile from './routes/profile/security/2fa-settings/setup-two-fa/2fa-settings.setup-two-fa.profile.route';
import TwoFaSettingsTwoFaProfile from './routes/profile/security/2fa-settings/twofa/2fa-settings.twofa.profile.route';
import TwoFaSettingsSecretKeyProfile from './routes/profile/security/2fa-settings/secret-key/2fa-settings.secret-key.profile.route';
import VisitHistory from './routes/profile/security/visit-history/visit-history.security.profile.route';
import ConvertLowBalancesSummary from './routes/wallet/convert-low-balances-summary/convert-low-balances.summary.wallet.route';
import Devices from './routes/profile/security/devices/devices.security.profile.route';
import WalletTransferSummary from './routes/wallet/transfer-summary/transfer.summary.wallet.route';
import AuthLayout from './components/auth';
import RegistrationPersonal from './routes/registration/registration.route';
import RegistrationEmailCode from './routes/registration/email-code/registration.email-code.route';
import RegistrationPhone from './routes/registration/phone/registration.phone.route';
import RegistrationPhoneCode from './routes/registration/phone-code/registration.phone-code.route';
import RegistrationSummary from './routes/registration/summary/registration.summary.route';
import Verification from './routes/verification/verification.route';
import QuestionariesVerification from './routes/verification/questionaries/questionaries.verification.route';
import QuestionariesTestVerification from './routes/verification/questionaries/test/questionaries.test.verification.route';
import QuestionariesSummaryVerification from './routes/verification/questionaries/summary/questionaries.summary.verification.route';
import VerificationSumSub from './routes/verification/sumsub/sumsub.verification.route';

export const router = createBrowserRouter(
	[
		{
			path: ROUTES.ROOT.INDEX,
			element: <AuthLayout />,
			errorElement: <ErrorLayout />,
		},
		{
			element: <GlobalRoute />,
			errorElement: <ErrorLayout />,
			children: [
				{
					element: <ProtectedRoute />,
					loader: globalRouteLoader,
					children: [
						{
							element: <PaymentsRoute />,
							children: [
								{
									path: ROUTES.DEPOSIT.CARD,
									element: <CardDeposit />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.DEPOSIT.INDEX,
									element: <Deposit />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.DEPOSIT.BANK_TRANSFER,
									element: <BankTransferDeposit />,
									loader: bankTransferLoader,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.DEPOSIT.BANK_TRANSFER_INVOICE,
									element: <BankTransferInvoiceDeposit />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.DEPOSIT.BANK_TRANSFER_INVOICE_HISTORY,
									loader: bankTransferLoader,
									element: <BankTransferInvoiceHistoryDeposit />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.DEPOSIT.CRYPTO,
									element: <CryptoDeposit />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.DEPOSIT.OPLATI,
									element: <OplatiDeposit />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.DEPOSIT.ERIP,
									element: <EripDeposit />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.WITHDRAW.INDEX,
									element: <Withdraw />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.WITHDRAW.CRYPTO.INDEX,
									element: <CryptoWithdraw />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.WITHDRAW.CRYPTO.CONFIRMATION,
									element: <CryptoWithdrawConfirmation />,
									errorElement: <ErrorLayout />,
								},

								{
									path: ROUTES.WITHDRAW.CRYPTO.SUMMARY,
									element: <CryptoWithdrawSummary />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.WITHDRAW.CARD.INDEX,
									element: <CardWithdraw />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.WITHDRAW.CARD.CONFIRMATION,
									element: <CardWithdrawConfirmation />,
									errorElement: <ErrorLayout />,
								},

								{
									path: ROUTES.WITHDRAW.CARD.SUMMARY,
									element: <CardWithdrawSummary />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.WITHDRAW.BANK.INDEX,
									element: <BankWithdraw />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.WITHDRAW.BANK.CONFIRMATION,
									element: <BankWithdrawConfirmation />,
									errorElement: <ErrorLayout />,
								},

								{
									path: ROUTES.WITHDRAW.BANK.SUMMARY,
									element: <BankWithdrawSummary />,
									errorElement: <ErrorLayout />,
								},

								{
									path: ROUTES.WITHDRAW.TWO_FA.TWO_FA,
									element: <WithdrawTwoFa />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.WITHDRAW.TWO_FA.SELECT_TWO_FA,
									element: <WithdrawSelectTwoFa />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.WITHDRAW.TWO_FA.OTP,
									element: <WithdrawOTP />,
									errorElement: <ErrorLayout />,
								},
							],
						},
						{
							element: <ExchangeRoute />,
							loader: exchangeLoader,
							children: [
								{
									path: ROUTES.EXCHANGE.INDEX,
									element: <Exchange />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.EXCHANGE.CONFIRMATION,
									element: <ExchangeConfirmation />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.EXCHANGE.SUMMARY,
									element: <ExchangeSummary />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.MARKET.ASSET_DETAILS,
									element: <AssetDetails />,
									errorElement: <ErrorLayout />,
								},
							],
						},
						{
							element: <ProfileRoute />,
							loader: aboutLoader,
							children: [
								{
									path: ROUTES.PROFILE.INDEX,
									element: <Profile />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.PROFILE.APP_SETTINGS,
									element: <ProfileSettings />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.PROFILE.DOCUMENTS,
									element: <ProfileMyDocuments />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.PROFILE.ACCOUNT_INFO,
									element: <ProfileAccountInfo />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.PROFILE.SAVED_ADDRESSES,
									element: <SavedAddresses />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.PROFILE.SECURITY.INDEX,
									element: <ProfileSecurity />,
									errorElement: <ErrorLayout />,
								},

								{
									path: ROUTES.PROFILE.SECURITY.CHANGE_PASSWORD.INDEX,
									element: <ProfileSecurityChangePassword />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.PROFILE.SECURITY.CHANGE_PASSWORD.TWO_FA,
									element: <ProfileSecurityTwoFactor />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.PROFILE.SECURITY.CHANGE_PASSWORD
										.SELECT_TWO_FA,
									element: <ProfileSecuritySelectTwoFactor />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.PROFILE.SECURITY.CHANGE_PASSWORD.OTP,
									element: <ProfileSecurityOTP />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.PROFILE.SECURITY.TWO_FA.INDEX,
									element: <TwoFaSettingsProfile />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.PROFILE.SECURITY.TWO_FA.OTP,
									element: <TwoFaSettingsOTPProfile />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.PROFILE.SECURITY.TWO_FA.SETUP_TWOFA,
									element: <TwoFaSettingsSetupProfile />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.PROFILE.SECURITY.TWO_FA.TWO_FA,
									element: <TwoFaSettingsTwoFaProfile />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.PROFILE.SECURITY.TWO_FA.SECRET_KEY,
									loader: secretKeyLoader,
									element: <TwoFaSettingsSecretKeyProfile />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.PROFILE.SECURITY.VISIT_HISTORY,
									element: <VisitHistory />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.PROFILE.SECURITY.DEVICES,
									element: <Devices />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.HISTORY.INDEX,
									element: <History />,
									loader: historyRouteLoader,
									errorElement: <ErrorLayout />,
								},
							],
						},
						{
							element: <VerificationRoute />,
							loader: registrationLoader,
							children: [
								{
									path: ROUTES.VERIFICATION.INDEX,
									element: <Verification />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.VERIFICATION.QUESTIONARY.INDEX,
									element: <QuestionariesVerification />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.VERIFICATION.QUESTIONARY.TEST,
									element: <QuestionariesTestVerification />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.VERIFICATION.QUESTIONARY.SUMMARY,
									element: <QuestionariesSummaryVerification />,
									errorElement: <ErrorLayout />,
								},
								{
									path: ROUTES.VERIFICATION.SUMSUB,
									element: <VerificationSumSub />,
									errorElement: <ErrorLayout />,
								},
							],
						},

						{
							path: ROUTES.WALLET.INDEX,
							element: <Wallet />,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.MARKET.INDEX,
							element: <Market />,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.SEARCH.INDEX,
							element: <Search />,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.WALLET.CONVERT_LOW_BALANCES_SUMMARY,
							element: <ConvertLowBalancesSummary />,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.WALLET.TRANSFER_SUMMARY,
							element: <WalletTransferSummary />,
							errorElement: <ErrorLayout />,
						},
					],
				},
				{
					element: <RegistrationRoute />,
					loader: registrationLoader,
					children: [
						{
							path: ROUTES.REGISTRATION.INDEX,
							element: <RegistrationPersonal />,
						},
						{
							path: ROUTES.REGISTRATION.EMAIL_CODE,
							element: <RegistrationEmailCode />,
						},
						{
							path: ROUTES.REGISTRATION.PHONE,
							element: <RegistrationPhone />,
						},
						{
							path: ROUTES.REGISTRATION.PHONE_CODE,
							element: <RegistrationPhoneCode />,
						},
						{
							path: ROUTES.REGISTRATION.SUMMARY,
							element: <RegistrationSummary />,
						},
					],
				},
				{
					children: [
						{
							path: ROUTES.ABOUT.LEGAL_STATEMENTS,
							element: <LegalStatements />,
							loader: legalStatementsLoader,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.ABOUT.HELP,
							element: <Help />,
							loader: helpLoader,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.ABOUT.COMMUNITY,
							element: <Community />,
							loader: communityLoader,
							errorElement: <ErrorLayout />,
						},
					],
				},
				{
					element: <CommonRoute />,
					children: [
						{
							path: ROUTES.MAIN.INDEX,
							element: <Main />,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.AUTH.INDEX,
							element: <Auth />,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.AUTH.SETUP_BIOMETRY,
							element: <Biometry />,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.AUTH.LOGIN,
							element: <LoginLayout />,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.AUTH.TWO_FA,
							element: <LoginTwoFactor />,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.AUTH.OTP,
							element: <LoginOTP />,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.AUTH.SETUP_TWOFA,
							element: <LoginSetupTwoFa />,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.AUTH.SECRET_KEY,
							element: <LoginSecretKey />,
							loader: secretKeyLoader,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.AUTH.SELECT_TWOFA,
							element: <LoginSelectTwoFa />,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.AUTH.RECOVERY.PASSWORD_RECOVERY,
							element: <PasswordRecovery />,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.AUTH.RECOVERY.CONFIRM_EMAIL,
							element: <PasswordRecoveryOTP />,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.AUTH.RECOVERY.NEW_PASSWORD,
							element: <PasswordRecoveryChangePassword />,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.INACTIVE.INDEX,
							element: <InActive />,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.ABOUT.INDEX,
							element: <About />,
							loader: aboutLoader,
							errorElement: <ErrorLayout />,
						},
						{
							path: ROUTES.MAINTAIN.INDEX,
							element: <Maintain />,
							errorElement: <ErrorLayout />,
						},
					],
				},
			],
		},
	],
	{
		future: {
			v7_partialHydration: true,
			v7_normalizeFormMethod: true,
			v7_fetcherPersist: true,
			v7_relativeSplatPath: true,
		},
	}
);

export const ROUTES = {
	MAIN: {
		INDEX: '/:locale/main',
	},
	PROFILE: {
		INDEX: '/:locale/profile',
		APP_SETTINGS: '/:locale/profile/settings',
		ACCOUNT_INFO: '/:locale/profile/info',
		DOCUMENTS: '/:locale/profile/documents',
		SAVED_ADDRESSES: '/:locale/profile/addresses',
		SECURITY: {
			INDEX: '/:locale/profile/security',
			TWO_FA: {
				INDEX: '/:locale/profile/security/2fa-settings',
				TWO_FA: '/:locale/profile/security/2fa-settings/2fa',
				OTP: '/:locale/profile/security/2fa-settings/otp',
				SECRET_KEY: '/:locale/profile/security/2fa-settings/secretkey',
				SETUP_TWOFA: '/:locale/profile/security/2fa-settings/setupTwoFa',
			},
			CHANGE_PASSWORD: {
				INDEX: '/:locale/profile/security/change',
				TWO_FA: '/:locale/profile/security/change/2fa',
				OTP: '/:locale/profile/security/change/otp',
				SELECT_TWO_FA: '/:locale/profile/security/change/selectTwoFa'
			},
			VISIT_HISTORY: '/:locale/profile/security/visitHistory',
			DEVICES: '/:locale/profile/security/devices'
		}
	},
	HISTORY: {
		INDEX: '/:locale/history',
	},
	MAINTAIN: {
		INDEX: '/:locale/maintain',
	},
	WALLET: {
		INDEX: '/:locale/wallet',
		CONVERT_LOW_BALANCES_SUMMARY: '/:locale/wallet/convert/summary',
		TRANSFER_SUMMARY: '/:locale/wallet/transfer/summary'
	},
	EXCHANGE: {
		INDEX: '/:locale/exchange',
		CONFIRMATION: '/:locale/exchange/confirmation',
		SUMMARY: '/:locale/exchange/summary',
	},
	MARKET: {
		INDEX: '/:locale/market',
		ASSET_DETAILS: '/:locale/market/:code',
	},
	SEARCH: {
		INDEX: '/:locale/search',
	},
	ROOT: {
		INDEX: '*',
	},
	INACTIVE: {
		INDEX: '/:locale/inactive',
	},
	AUTH: {
		INDEX: '/:locale/auth',
		LOGIN: '/:locale/auth/login',
		TWO_FA: '/:locale/auth/login/2fa',
		OTP: '/:locale/auth/login/otp',
		SETUP_BIOMETRY: '/:locale/auth/login/biometry',
		SETUP_TWOFA: '/:locale/auth/login/setupTwoFa',
		SECRET_KEY: '/:locale/auth/login/secretKey',
		CONFIRM_TWOFA: '/:locale/auth/login/confirmTwoFa',
		SELECT_TWOFA: '/:locale/auth/login/selectTwoFa',
		RECOVERY: {
			PASSWORD_RECOVERY: '/:locale/auth/login/recovery',
			CONFIRM_EMAIL: '/:locale/auth/login/recovery/otp',
			NEW_PASSWORD: '/:locale/auth/login/recovery/password',
			CONFIRM_TWOFA: '/:locale/auth/login/recovery/twofa',
		},
	},
	REGISTRATION: {
		INDEX: "/:locale/registration",
		EMAIL_CODE: "/:locale/registration/code",
		PHONE: "/:locale/registration/phone",
		PHONE_CODE: "/:locale/registration/phone/code",
		SUMMARY: "/:locale/registration/summary",
	},
	VERIFICATION: {
		INDEX: "/:locale/verification",
		QUESTIONARY: {
			INDEX: "/:locale/verification/questionaries",
			TEST: "/:locale/verification/questionaries/test",
			SUMMARY: "/:locale/verification/questionaries/summary"
		},
		SUMSUB: "/:locale/verification/sumsub"
	},
	ABOUT: {
		INDEX: '/:locale/about',
		HELP: '/:locale/about/help',
		LEGAL_STATEMENTS: '/:locale/about/statements',
		COMMUNITY: '/:locale/about/community',
	},
	DEPOSIT: {
		INDEX: '/:locale/payments/deposit',
		ERIP: '/:locale/payments/deposit/erip',
		OPLATI: '/:locale/payments/deposit/oplati',
		CARD: '/:locale/payments/deposit/card',
		CRYPTO: '/:locale/payments/deposit/crypto',
		BANK_TRANSFER: '/:locale/payments/deposit/transfer',
		BANK_TRANSFER_INVOICE: '/:locale/payments/deposit/transfer/:invoiceId',
		BANK_TRANSFER_INVOICE_HISTORY: '/:locale/payments/deposit/transfer/history',
	},
	WITHDRAW: {
		INDEX: '/:locale/payments/withdraw',
		CARD: {
			INDEX: '/:locale/payments/withdraw/card',
			CONFIRMATION: '/:locale/payments/withdraw/card/confirmation',
			SUMMARY: '/:locale/payments/withdraw/card/summary',
		},
		CRYPTO: {
			INDEX: '/:locale/payments/withdraw/crypto',
			CONFIRMATION: '/:locale/payments/withdraw/crypto/confirmation',
			SUMMARY: '/:locale/payments/withdraw/crypto/summary',
		},
		BANK: {
			INDEX: '/:locale/payments/withdraw/bank',
			CONFIRMATION: '/:locale/payments/withdraw/bank/confirmation',
			SUMMARY: '/:locale/payments/withdraw/bank/summary',
		},
		TWO_FA: {
			TWO_FA: '/:locale/payments/withdraw/twofa',
			OTP: '/:locale/payments/withdraw/otp',
			SELECT_TWO_FA: '/:locale/payments/withdraw/selectTwoFa'
		}
	},
};

export const GA_ROUTES = {
	APPLE: 'https://apps.apple.com/ru/app/google-authenticator/id388497605',
	ANDROID:
		'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pli=1',
};

export const COMMON_ROUTES = {
	LK: 'https://my.free2ex.com/',
};

import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { TCountry } from '../../../types/types';
import { CustomInput } from '../../common/inputs';
import { CountryModal } from '../../common/modals';
import { useSendPhoneCodeMutation } from '../../../data/mutations/registration';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { mapClassnameForMobile } from '../../../utils';
import {
	selectRegistrationCountry,
	selectRegistrationFullPhone,
	selectRegistrationInfo,
	selectRegistrationPhone,
	selectRegistrationState,
	setRegistrationCountry,
	setRegistrationFullPhone,
	setRegistrationPhone,
} from '../../../services/registration';
import { selectCountriesDictionary } from '../../../services/dictionaries';
import { HeaderLayout } from '../../common/header';
import { CustomButton } from '../../common/buttons';
import { APIError } from '../../common/error';

const RegistrationPhoneLayout = () => {
	const countries = useAppSelector(selectCountriesDictionary);
	const state = useAppSelector(selectRegistrationState);
	const phone = useAppSelector(selectRegistrationPhone);
	const stateInfo = useAppSelector(selectRegistrationInfo);
	const country = useAppSelector(selectRegistrationCountry);
	const fullPhone = useAppSelector(selectRegistrationFullPhone);

	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const [phoneErrorFake, setPhoneErrorFake] = useState<boolean>(false);

	const { askCode, mutationError, isError, isPending } =
		useSendPhoneCodeMutation(navigate);

	useEffect(() => {
		if (isError) {
			setPhoneErrorFake(isError);
		}
	}, [isError]);

	useEffect(() => {
		if (stateInfo && stateInfo?.phone === '') {
			dispatch(
				setRegistrationCountry({
					code: t('registration_code'),
					imgUrl: '',
				})
			);
		}
	}, [stateInfo]);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const buttonRef = useRef(null);

	const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		console.log(e);
		setPhoneErrorFake(false);
		dispatch(setRegistrationPhone(e?.target?.value));
		dispatch(setRegistrationFullPhone(country?.code + e?.target?.value));
	};

	const handlePhoneClear = () => {
		dispatch(setRegistrationPhone(''));
		setPhoneErrorFake(false);
	};

	const handleCountrySelect = () => {
		if(!stateInfo?.isPhoneVerified) {
			setIsModalOpen(!isModalOpen);
		}
	};

	const handleSelectClick = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleCountryCodeChange = (value: { code: string; imgUrl: string }) => {
		dispatch(
			setRegistrationCountry({
				code: value.code,
				imgUrl: value.imgUrl,
			})
		);
	};

	const handleBackClick = () => {
		navigate(ROUTES.REGISTRATION.INDEX);
	};

	const handleSubmit = () => {
		setPhoneErrorFake(false);
		if (stateInfo?.isPhoneVerified) {
			navigate(ROUTES.REGISTRATION.SUMMARY);
			return;
		}
		if (phone?.length === 0 || country?.code === undefined || countries === undefined) {
			setPhoneErrorFake(true);
			return;
		} else {
			const dictionaryCountry = countries?.dictionary?.find(
				(dictCountry: TCountry) => dictCountry.phoneCode === country?.code
			);
			askCode({
				identity: fullPhone,
				identityType: 'phone',
				state: state,
				dispatch: dispatch,
				phoneCountryCode: dictionaryCountry?.code as string,
			});
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<CountryModal
					countries={countries?.dictionary as TCountry[]}
					isModalOpen={isModalOpen}
					handleIsModalOpen={handleSelectClick}
					handleItemClick={handleCountryCodeChange}
				/>
				<HeaderLayout isFunctionalHeader handleBackClick={handleBackClick} />
				<div className={mapClassnameForMobile('walletLayout')}>
					<div className="innerContainer">
						<h1 className={'confirmEmailText'}>
							{`${t('registration_mobilePhone')} ${t(
								'registration_phone'
							)}`}
						</h1>
						<h1 className={'confirmCodeText'}>
							{t('registration_provideYourPhone')}
						</h1>
						<div className={'registrationInputContainer'}>
							<CustomInput
								isDisabled={stateInfo?.isPhoneVerified}
								isRequired={country?.code != t('registration_code')}
								type={'text'}
								id={'countryCodeSelect'}
								label={
									country?.code != t('registration_code')
										? (t('registration_code') as string)
										: ''
								}
								value={country?.code as string}
								onClearClick={handleCountrySelect}
								className={'countrySelectInput'}
								isSelectCode={true}
								imgUrl={country?.imgUrl as string}
							/>
							<CustomInput
								isError={phoneErrorFake}
								isRequired={phone?.length > 0}
								isDisabled={stateInfo?.isPhoneVerified}
								isNonCloseIcon={stateInfo?.isPhoneVerified}
								type={'text'}
								id={'number'}
								label={t('registration_phone')}
								value={phone}
								onChange={handlePhoneChange}
								className={'phoneInput'}
								onClearClick={handlePhoneClear}
								placeHolder={'00-000-00-00'}
								isMobile={true}
								inputPropsRef={buttonRef}
							/>
						</div>
						<APIError error={mutationError} isError={phoneErrorFake} />
					</div>
				</div>
			</div>
			<div className="depositButtonsBottomContainer">
				<CustomButton
					buttonRef={buttonRef}
					text={t('login_continue') as string}
					type={'submit'}
					onClick={handleSubmit}
					className={'confirmFullWidthButton'}
					isLoading={isPending}
				/>
			</div>
		</div>
	);
};

export default RegistrationPhoneLayout;

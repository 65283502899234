import { useMutation } from "@tanstack/react-query";
import { TCustomNavigate, TDefaultBody, TVerificationSendEulaCheckBoxesBody, TVerificationSendProfileBody, TVerificationSendQuiestionariesBody } from "../../../types/types";
import { fetchVerificationGetEula, fetchVerificationGetProfile, fetchVerificationGetQuestionary, fetchVerificationInfo, fetchVerificationSendEula, fetchVerificationSendProfile, fetchVerificationSendQuestionary, fetchVerificationSumSub } from "../../../api/verification";
import { ROUTES } from "../../../types/routes";
import { AppDispatch } from "../../../store";
import { setVerificationInfo } from "../../../services/user";
import { setVerificationEula, setVerificationIsTestSucesss, setVerificationQuestionaries, setVerificationSumSubAccessToken, setVerificationUserInfo } from "../../../services/verification";

export function useGetSumSubStatus(
    dispatch: AppDispatch
) {
    const { mutateAsync: getSumSubStatus, isError, error, isPending } = useMutation({
        mutationKey: ['getSumSubStatus'],
        mutationFn: (data: TDefaultBody) => fetchVerificationSumSub(data),
        //eslint-disable-next-line
        onSuccess: async (data: any) => {
            if(data?.widgetConfiguration) {
                dispatch(setVerificationSumSubAccessToken(data?.widgetConfiguration?.accessToken))
            }
        },
    });
    return {
        getSumSubStatus,
        isError,
        error,
        isPending
    };
}

export function useGetVerificationEula(
    dispatch: AppDispatch
) {
    const { mutateAsync: getVerificationEula, isError, error, isPending } = useMutation({
        mutationKey: ['getVerificationEula'],
        mutationFn: (data: TDefaultBody) => fetchVerificationGetEula(data),
        //eslint-disable-next-line
        onSuccess: async (data: any) => {
            dispatch(setVerificationEula(data))
        },
    });
    return {
        getVerificationEula,
        isError,
        error,
        isPending
    };
}

export function useSendVerificationEula() {
    const { mutateAsync: sendVerificationEula, isError, error, isPending } = useMutation({
        mutationKey: ['sendVerificationEula'],
        mutationFn: (data: TVerificationSendEulaCheckBoxesBody) => fetchVerificationSendEula(data),
    });
    return {
        sendVerificationEula,
        isError,
        error,
        isPending
    };
}

export function useGetVerificationProfile(
    dispatch: AppDispatch
) {
    const { mutateAsync: getVerificationProfile, isError, error, isPending } = useMutation({
        mutationKey: ['getVerificationProfile'],
        mutationFn: (data: TDefaultBody) => fetchVerificationGetProfile(data),
        //eslint-disable-next-line
        onSuccess: async (data: any) => {
            dispatch(setVerificationUserInfo(data))
        },
    });
    return {
        getVerificationProfile,
        isError,
        error,
        isPending
    };
}

export function useSendVerificationProfile(navigate: TCustomNavigate) {
    const { mutateAsync: sendVerificationProfile, isError, error, isPending } = useMutation({
        mutationKey: ['sendVerificationProfile'],
        mutationFn: (data: TVerificationSendProfileBody) => fetchVerificationSendProfile(data),
        onSuccess: async () => {
            navigate(ROUTES.WALLET.INDEX)
        }
    });
    return {
        sendVerificationProfile,
        isError,
        error,
        isPending
    };
}

export function useGetVerificationQuestionaries(
    dispatch: AppDispatch
) {
    const { mutateAsync: getVerificationQuestionaries, isError, error, isPending } = useMutation({
        mutationKey: ['getVerificationQuestionaries'],
        mutationFn: (data: TDefaultBody) => fetchVerificationGetQuestionary(data),
        //eslint-disable-next-line
        onSuccess: async (data: any) => {
            dispatch(setVerificationQuestionaries(data))
        },
    });
    return {
        getVerificationQuestionaries,
        isError,
        error,
        isPending
    };
}

export function useSendVerificationQuestionaries(navigate: TCustomNavigate, dispatch: AppDispatch) {
    const { mutateAsync: sendVerificationQuestionaries, isError, error, isPending } = useMutation({
        mutationKey: ['sendVerificationQuestionaries'],
        mutationFn: (data: TVerificationSendQuiestionariesBody) => fetchVerificationSendQuestionary(data),
        onSuccess: async () => {
            dispatch(setVerificationIsTestSucesss(true))
            navigate(ROUTES.VERIFICATION.QUESTIONARY.SUMMARY)
        },
        onError: async () => {
            dispatch(setVerificationIsTestSucesss(false))
            navigate(ROUTES.VERIFICATION.QUESTIONARY.SUMMARY)
        }
    });
    return {
        sendVerificationQuestionaries,
        isError,
        error,
        isPending
    };
}

export function useGetVerificationInfo(
    dispatch: AppDispatch
) {
    const { mutateAsync: getVerificationInfo, isError, error, isPending } = useMutation({
        mutationKey: ['getVerificationInfo'],
        mutationFn: (data: TDefaultBody) => fetchVerificationInfo(data),
        //eslint-disable-next-line
        onSuccess: async (data: any) => {
            dispatch(setVerificationInfo(data))
        },
    });
    return {
        getVerificationInfo,
        isError,
        error,
        isPending
    };
}
import { useMutation } from '@tanstack/react-query';
import {
	fetchAskNewCode,
	fetchRegistrationEula,
	fetchRegistrationInfo,
	fetchRegistrationStart,
	fetchSubmitClear,
	fetchSubmitCode,
	fetchSubmitFirstStep,
	fetchSubmitLastStep
} from '../../../api/registration';
import {
	TCustomNavigate,
	TRegistrationEulaBody,
	TRegistrationGetCodeBody,
	TRegistrationInfoBody,
	TRegistrationLastStepBody,
	TRegistrationSendCodeBody,
	TRegistrationSendEulaBody,
	TRegistrationStartResponse
} from '../../../types/types';
import { ROUTES } from '../../../types/routes';
import { AppDispatch } from '../../../store';
import {
	setRegistrationInfo,
	setRegistrationInit,
	setRegistrationState
} from '../../../services/registration';

export function useSubmitClearMutation(
	navigate: TCustomNavigate,
	dispatch: AppDispatch
) {
	const { mutateAsync: clearState } = useMutation({
		mutationKey: ['submitClearMutation'],
		mutationFn: (data: TRegistrationInfoBody) => fetchSubmitClear(data),
		onSuccess: async () => {
			dispatch(setRegistrationInit());
			navigate(ROUTES.AUTH.INDEX);
		},
		onError: async () => {
			dispatch(setRegistrationInit());
			navigate(ROUTES.AUTH.INDEX);
		}
	});
	return {
		clearState
	};
}

export function useGetStateInfoMutation(dispatch: AppDispatch) {
	const {
		mutateAsync: getStateInfo,
		error,
		isError,
		isPending
	} = useMutation({
		mutationKey: ['getStateInfo'],
		mutationFn: (data: TRegistrationInfoBody) =>
			fetchRegistrationInfo(data),
		onSuccess: async (data) => {
			dispatch(setRegistrationInfo(data));
		}
	});
	return {
		getStateInfo,
		error,
		isError,
		isPending
	};
}

export function useRegistrationGetEula() {
	const {
		mutateAsync: getRegistrationEula,
		isError,
		error: mutationError,
		isPending
	} = useMutation({
		mutationKey: ['getRegistrationEulaMutation'],
		mutationFn: (data: TRegistrationEulaBody) => fetchRegistrationEula(data)
	});
	return {
		getRegistrationEula,
		isPending,
		mutationError,
		isError
	};
}

export function useRegistrationStartMutation(
	navigate: TCustomNavigate,
	isRegistrationPageInited: boolean,
	dispatch: AppDispatch
) {
	const {
		mutateAsync: startRegistration,
		isError,
		error: mutationError,
		isPending
	} = useMutation({
		mutationKey: ['registrationStartMutation'],
		mutationFn: fetchRegistrationStart,
		onSuccess: async (data: TRegistrationStartResponse) => {
			if (data?.state) {
				dispatch(setRegistrationState(data?.state));
			}
			if (!isRegistrationPageInited) {
				navigate(ROUTES.REGISTRATION.INDEX);
			}
		}
	});
	return {
		startRegistration,
		isPending,
		mutationError,
		isError
	};
}

export function useSubmitFirstStepMutation(
	navigate: TCustomNavigate,
	isEmailVerified: boolean
) {
	const {
		mutateAsync: submitFirstStep,
		isError,
		error,
		isPending
	} = useMutation({
		mutationKey: ['submitFirstStepMutation'],
		mutationFn: (data: TRegistrationSendEulaBody) =>
			fetchSubmitFirstStep(data),
		onSuccess: async () => {
			if (isEmailVerified) {
				navigate(ROUTES.REGISTRATION.PHONE);
				return;
			}
			navigate(ROUTES.REGISTRATION.EMAIL_CODE);
		}
	});
	return {
		submitFirstStep,
		isPending,
		error,
		isError
	};
}

export function useAskNewEmailCodeMutation(notify?: () => void) {
	const {
		mutateAsync: askNewCode,
		isError,
		error: askNewCodeError
	} = useMutation({
		mutationKey: ['askNewEmailCodeMutation'],
		mutationFn: (data: TRegistrationGetCodeBody) => fetchAskNewCode(data),
		onSuccess: async () => {
			if(notify) {
				notify()
			}
		}
	});
	return {
		askNewCode,
		askNewCodeError,
		isError
	};
}

export function useSubmitEmailCodeMutation(navigate: TCustomNavigate) {
	const {
		mutateAsync: submitEmail,
		isError,
		error: mutationError,
		isPending
	} = useMutation({
		mutationKey: ['submitEmailCodeMutation'],
		mutationFn: (data: TRegistrationSendCodeBody) => fetchSubmitCode(data),
		onSuccess: async () => {
			navigate(ROUTES.REGISTRATION.PHONE);
		}
	});
	return {
		submitEmail,
		mutationError,
		isError,
		isPending
	};
}

export function useSendPhoneCodeMutation(navigate: TCustomNavigate) {
	const {
		mutateAsync: askCode,
		isError,
		error: mutationError,
		isPending
	} = useMutation({
		mutationKey: ['sendPhoneCodeMutation'],
		mutationFn: (data: TRegistrationGetCodeBody) => fetchAskNewCode(data),
		onSuccess: async () => {
			navigate(ROUTES.REGISTRATION.PHONE_CODE);
		}
	});
	return {
		askCode,
		mutationError,
		isError,
		isPending
	};
}

export function useAskNewPhoneCodeMutation(notify?: () => void) {
	const {
		mutateAsync: askNewCode,
		isError,
		error: askNewCodeError
	} = useMutation({
		mutationKey: ['askNewPhoneCodeMutation'],
		mutationFn: (data: TRegistrationGetCodeBody) => fetchAskNewCode(data),
		onSuccess: async () => {
			if(notify) {
				notify()
			}
		}
	});
	return {
		askNewCode,
		askNewCodeError,
		isError
	};
}

export function useSubmitPhoneCodeMutation(navigate: TCustomNavigate) {
	const {
		mutateAsync: submitPhone,
		isError,
		error: mutationError,
		isPending
	} = useMutation({
		mutationKey: ['submitPhoneCodeMutation'],
		mutationFn: (data: TRegistrationSendCodeBody) => fetchSubmitCode(data),
		onSuccess: async () => {
			navigate(ROUTES.REGISTRATION.SUMMARY);
		}
	});
	return {
		submitPhone,
		mutationError,
		isError,
		isPending
	};
}

export function useSubmitLastStepMutation(
	navigate: TCustomNavigate,
	dispatch: AppDispatch
) {
	const {
		mutateAsync: submitLastStep,
		isError,
		error: mutationError,
		isPending
	} = useMutation({
		mutationKey: ['submitLastStepMutation'],
		mutationFn: (data: TRegistrationLastStepBody) =>
			fetchSubmitLastStep(data),
		onSuccess: async () => {
			dispatch(setRegistrationInit());
			navigate(ROUTES.AUTH.LOGIN);
		}
	});
	return {
		submitLastStep,
		mutationError,
		isError,
		isPending
	};
}

import { useTranslation } from 'react-i18next';
import { mapClassnameForMobile } from '../../utils';
import { CustomButton } from '../common/buttons';
import { APIError } from '../common/error';
import { HeaderLayout } from '../common/header';
import { CustomInput } from '../common/inputs';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { ROUTES } from '../../types/routes';
import { useEffect, useRef, useState } from 'react';
import { useSendVerificationProfile } from '../../data/mutations/verification';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectAccessToken } from '../../services/auth';
import {
	selectVerificationCitizenship,
	selectVerificationDateOfBirth,
	selectVerificationFirstName,
	selectVerificationLastName,
	selectVerificationMiddleName,
	setVerificationCitizenship,
	setVerificationDateOfBirth,
	setVerificationFirstName,
	setVerificationLastName,
	setVerificationMiddleName,
} from '../../services/verification';
import { CountryModal, HistoryCustomPeriodModal } from '../common/modals';
import { selectCountriesDictionary } from '../../services/dictionaries';
import { TCountry, THistoryDatePeriodWithDate } from '../../types/types';

const VerificationLayout = () => {
	const countries = useAppSelector(selectCountriesDictionary);
	const firstName = useAppSelector(selectVerificationFirstName);
	const lastName = useAppSelector(selectVerificationLastName);
	const middleName = useAppSelector(selectVerificationMiddleName);
	const citizenship = useAppSelector(selectVerificationCitizenship);
	const dateOfBirth = useAppSelector(selectVerificationDateOfBirth);
	const accessToken = useAppSelector(selectAccessToken);

	const [inputError, setInputError] = useState<{
		[key: string]: boolean;
	}>({
		firstName: false,
		lastName: false,
		middleName: false,
		citizenship: false,
		dateOfBirth: false,
	});

	useEffect(() => {
		dispatch(
			setVerificationCitizenship({
				code: t('verification_citizenship'),
				imgUrl: '',
			})
		);
	}, []);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isHistoryCustomPeriodModalOpen, setIsHistoryCustomPeriodModalOpen] =
		useState<boolean>(false);

	const dispatch = useAppDispatch();
	const buttonRef = useRef(null);
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const { sendVerificationProfile, isError, error, isPending } =
		useSendVerificationProfile(navigate);

	const handleBackClick = () => {
		navigate(ROUTES.WALLET.INDEX);
	};

	const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setVerificationFirstName(e.target.value));
		setInputError({
			...inputError,
			firstName: false,
		});
	};

	const handleFirstNameClear = () => {
		dispatch(setVerificationFirstName(''));
		setInputError({
			...inputError,
			firstName: false,
		});
	};

	const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setVerificationLastName(e.target.value));
		setInputError({
			...inputError,
			lastName: false,
		});
	};

	const handleLastNameClear = () => {
		dispatch(setVerificationLastName(''));
		setInputError({
			...inputError,
			lastName: false,
		});
	};

	const handleMiddleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		dispatch(setVerificationMiddleName(e.target.value));
		setInputError({
			...inputError,
			middleName: false,
		});
	};

	const handleMiddleNameClear = () => {
		dispatch(setVerificationMiddleName(''));
		setInputError({
			...inputError,
			middleName: false,
		});
	};

	const handleCountrySelect = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleCountryChange = (value: { code: string; imgUrl: string }) => {
		setInputError({
			...inputError,
			citizenship: false,
		});
		dispatch(
			setVerificationCitizenship({
				code: value.code,
				imgUrl: value.imgUrl,
			})
		);
	};

	const handleCustomPeriodModalOpen = () => {
		setIsHistoryCustomPeriodModalOpen(!isHistoryCustomPeriodModalOpen);
	};

	const handleCustomPeriodSubmit = (e: THistoryDatePeriodWithDate) => {
		dispatch(
			setVerificationDateOfBirth({
				shortDate: e.dateFrom?.format('DD/MM/YYYY').replaceAll('/', '') as string,
				fullDate: e.dateFrom?.format('DD/MM/YYYY').replaceAll('/', '.') as string,
			}
			)
		);
		handleCustomPeriodModalOpen();
	};

	const handleSubmit = () => {
		setInputError({
			firstName: false,
			lastName: false,
			middleName: false,
			citizenship: false,
			dateOfBirth: false,
		});
		if (
			dateOfBirth?.shortDate?.length === 0 ||
			citizenship?.code?.length === 0 ||
			lastName?.length === 0 ||
			firstName?.length === 0 ||
			middleName?.length === 0
		) {
			const tempInputError = {
				firstName: false,
				lastName: false,
				middleName: false,
				citizenship: false,
				dateOfBirth: false,
			}
			if (firstName?.length === 0) {
				tempInputError.firstName = true
			}
			if (lastName?.length === 0) {
				tempInputError.lastName = true
			}
			if (middleName?.length === 0) {
				tempInputError.middleName = true
			}
			if (citizenship?.code?.length === 0) {
				tempInputError.citizenship = true
			}
			if (dateOfBirth?.shortDate?.length === 0) {
				tempInputError.middleName = true
			}
			setInputError(tempInputError);
			return;
		} else {
			setInputError({
				firstName: false,
				lastName: false,
				middleName: false,
				citizenship: false,
				dateOfBirth: false,
			});
			sendVerificationProfile({
				dispatch: dispatch,
				accessToken: accessToken as string,
				firstName: firstName,
				lastName: lastName,
				middleName: middleName,
				dateOfBirth: dateOfBirth?.shortDate as string,
				citizenship: countries?.dictionary?.find((country) => country?.name === citizenship?.code)?.code as string,
			});
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<CountryModal
					isVerification
					countries={countries?.dictionary as TCountry[]}
					isModalOpen={isModalOpen}
					handleIsModalOpen={handleCountrySelect}
					handleItemClick={handleCountryChange}
				/>
				<HistoryCustomPeriodModal
					isVerification
					handleIsModalOpen={handleCustomPeriodModalOpen}
					isModalOpen={isHistoryCustomPeriodModalOpen}
					handleSubmit={handleCustomPeriodSubmit}
				/>
				<HeaderLayout isFunctionalHeader handleBackClick={handleBackClick} />
				<div className={mapClassnameForMobile('walletLayout')}>
					<div className="innerContainer">
						<h1 className={'innerContainerMainText'}>
							{t('verification_title')}
						</h1>
						<CustomInput
							isError={inputError?.firstName}
							isRequired={firstName?.length > 0}
							type={'text'}
							id={'firstName'}
							label={t('verification_firstName')}
							value={firstName}
							onChange={handleFirstNameChange}
							className={'input'}
							onClearClick={handleFirstNameClear}
							inputPropsRef={buttonRef}
						/>
						<CustomInput
							isError={inputError?.middleName}
							isRequired={middleName?.length > 0}
							type={'text'}
							id={'middleName'}
							label={t('verification_patronymic')}
							value={middleName}
							onChange={handleMiddleNameChange}
							className={'input'}
							onClearClick={handleMiddleNameClear}
							inputPropsRef={buttonRef}
						/>
						<CustomInput
							isError={inputError?.lastName}
							isRequired={lastName?.length > 0}
							type={'text'}
							id={'lastName'}
							label={t('verification_lastName')}
							value={lastName}
							onChange={handleLastNameChange}
							className={'input'}
							onClearClick={handleLastNameClear}
							inputPropsRef={buttonRef}
						/>

						<CustomInput
							isError={inputError?.dateOfBirth}
							isRequired={
								(dateOfBirth &&
									dateOfBirth?.shortDate?.length > 0) as boolean
							}
							type={'text'}
							id={'dateOfBirth'}
							label={t('verification_dateOfBirth')}
							value={dateOfBirth?.fullDate as string}
							className={'input'}
							onClearClick={handleCustomPeriodModalOpen}
							inputPropsRef={buttonRef}
							isSelectCode={true}
						/>
						<CustomInput
							isError={inputError?.citizenship}
							isRequired={
								citizenship?.code != t('verification_citizenship')
							}
							type={'text'}
							id={'countryCodeSelect'}
							label={
								citizenship?.code != t('verification_citizenship')
									? (t('verification_citizenship') as string)
									: ''
							}
							value={citizenship?.code as string}
							onClearClick={handleCountrySelect}
							className={'input'}
							isSelectCode={true}
							imgUrl={citizenship?.imgUrl as string}
						/>
						<APIError error={error} isError={isError} />
					</div>
				</div>
			</div>
			<div className="depositButtonsBottomContainer">
				<CustomButton
					buttonRef={buttonRef}
					text={t('verificationInfo_continue') as string}
					type={'submit'}
					onClick={handleSubmit}
					className={'confirmFullWidthButton'}
					isLoading={isPending}
				/>
			</div>
		</div>
	);
};
export default VerificationLayout;

export const TITLES = {
	MAIN: {
		INDEX: 'Main',
	},
	WALLET: {
		CONVERT_LOW_BALANCES_SUMMARY: 'Convert Low Balances | Summary',
		TRANSFER_SUMMARY: 'Transfer | Summary'
	},
	REGISTRATION: {
		INDEX: "Registration",
		EMAIL: "Email Code | Registration",
		PHONE: "Phone | Registration",
		PHONE_CODE: "Phone Code | Registration",
		DATA: "Data | Registration",
	},
	PROFILE: {
		INDEX: 'Profile',
		SETTINGS: 'Settings | Profile',
		DOCUMENTS: 'My Documents | Profile',
		ACCOUNT_INFO: 'Account Info | Profile',
		SAVED_ADDRESSES: 'Saved Addresses | Profile',
		SECURITY: {
			INDEX: 'Security',
			CHANGE_PASSWORD: {
				INDEX: 'Change Password | Security',
				TWO_FA: 'TwoFa | Change Password',
				SELECT_TWO_FA: 'Select TwoFa | Change Password',
				OTP: 'OTP | Change Password',
			},
			TWO_FA_SETTINGS: {
				INDEX: 'TwoFa Settings',
				OTP: 'OTP | TwoFa Settings',
				TWO_FA: 'TwoFa | TwoFa Settings',
				SETUP: 'Setup TwoFA | TwoFa Settings',
				SECRET_KEY: 'Secret Key | TwoFa Settings',
			},

			CHANGE_TWOFA_FACTOR: 'Change Two Factor | Security',
			QUICK_LOGIN: 'Quick Login | Security',
			VISIT_HISTORY: 'Visit History | Security',
			DEVICES: 'Devices | Security',
		},
	},
	HISTORY: {
		INDEX: 'History',
	},
	ROOT: {
		INDEX: 'Wallet',
	},
	MAINTAIN: {
		INDEX: 'Maintain',
	},
	EXCHANGE: {
		INDEX: 'Exchange',
		CONFIRMATION: 'Confirmation | Exchange',
		SUMMARY: 'Summary | Exchange',
	},
	MARKET: {
		INDEX: 'Market',
		ASSET_DETAILS: 'Asset Details',
	},
	SEARCH: {
		INDEX: 'Search',
	},
	AUTH: {
		INDEX: 'Authentication',
		LOGIN: 'Login',
		TWOFA: 'TwoFA | Login',
		SELECT_TWOFA: 'Select TwoFa | Login',
		SETUP_BIOMETRY: 'Setup Biometry | Login',
		OTP: 'OTP | Login',
		SETUP: 'Setup TwoFA | Login',
		SECRET_KEY: 'Secret Key | Login',
		SENT: 'Sent | Login',
		IMPERSONATE: 'Impersonate | Login',
		RECOVERY: {
			PASSWORD_RECOVERY: 'Email | Recovery',
			CONFIRM_EMAIL: 'Confirm Code | Recovery',
			NEW_PASSWORD: 'New Password | Recovery',
		},
	},
	ABOUT: {
		INDEX: 'About',
		HELP: 'Help & Support',
		LEGAL_STATEMENTS: 'Legal Statements',
		COMMUNITY: 'Community',
	},
	DEPOSIT: {
		INDEX: 'Deposit',
		OPLATI: 'Oplati | Deposit',
		ERIP: 'ERIP | Deposit',
		CRYPTO: 'Crypto | Deposit',
		BANK_TRANSFER: 'Bank Transfer | Deposit',
		BANK_TRANSFER_INVOICE: 'Invoice | Deposit',
		BANK_TRANSFER_INVOICE_HISTORY: 'History | Deposit',
		CARD: 'Card | Deposit',
	},
	WITHDRAW: {
		INDEX: 'Withdraw',
		TWO_FA: {
			TWO_FA: 'TwoFa | Withdraw',
			SELECT_TWO_FA: 'Select TwoFa | Withdraw',
			OTP: 'OTP | Withdraw',
		},
		CRYPTO: {
			INDEX: 'Crypto | Withdraw',
			CONFIRMATION: 'Confirmation | Crypto Withdraw',
			SUMMARY: 'Summary | Crypto Withdraw',
		},
		CARD: {
			INDEX: 'Card | Withdraw',
			CONFIRMATION: 'Confirmation | Card Withdraw',
			SUMMARY: 'Summary | Card Withdraw',
		},
		BANK: {
			INDEX: 'Bank | Withdraw',
			CONFIRMATION: 'Confirmation | Bank Withdraw',
			SUMMARY: 'Summary | Bank Withdraw',
		},
	},
	VERIFICATION: {
		INDEX: 'Verification',
		QUESTIONARIES: {
			INDEX: 'Questionaries | Verification',
			TEST: 'Test | Questionaries',
			SUMMARY: 'Summary | Questionaries'
		},
		SUMSUB: 'SumSub | Verification'
	}
};

import { useEffect, useRef } from 'react';
import { CustomButton } from '../../common/buttons';
import { HeaderLayout } from '../../common/header';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { mapClassnameForMobile } from '../../../utils';
import { useGetVerificationQuestionaries } from '../../../data/mutations/verification';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectAccessToken } from '../../../services/auth';
import { APIError } from '../../common/error';
import { WhaleIcon } from '../../common/icons';
import { selectVerificationQuestionaries } from '../../../services/verification';

const VerificationQuestionariesLayout = () => {
	const questionaries = useAppSelector(selectVerificationQuestionaries);
	const accessToken = useAppSelector(selectAccessToken);

	const buttonRef = useRef(null);
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const {getVerificationQuestionaries, isError, error} = useGetVerificationQuestionaries(dispatch);

	useEffect(() => {
		getVerificationQuestionaries({
			accessToken: accessToken as string,
			dispatch: dispatch,
		})
	},[])

	const handleBackClick = () => {
		navigate(ROUTES.WALLET.INDEX);
	};

	const handleSubmit = () => {
		navigate(ROUTES.VERIFICATION.QUESTIONARY.TEST);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout isFunctionalHeader handleBackClick={handleBackClick} />
				<div className={mapClassnameForMobile('walletLayout', true)}>
					<div className="innerContainer">
						<WhaleIcon className='walletConfirmIdentityWhale' />
						<h1 className='verificationQuestionariesTextHeader'>{questionaries?.title}</h1>
						<h1 className='verificationQuestionariesTextBottom'>{questionaries?.text}</h1>
					</div>
				</div>
			</div>
			<div className="depositButtonsBottomContainer">
				<APIError isError={isError} error={error}/>
				<CustomButton
					buttonRef={buttonRef}
					text={t('quiz_startTest') as string}
					type={'submit'}
					onClick={handleSubmit}
					className={'confirmFullWidthButton'}
				/>
			</div>
		</div>
	);
};

export default VerificationQuestionariesLayout;

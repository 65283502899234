import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSendVerificationQuestionaries } from '../../../../data/mutations/verification';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';
import {
	selectVerificationCurrentQuestion,
	selectVerificationQuestionaries,
	selectVerificationTestQuestionaries,
	setVerificationCurrentQuestion,
	setVerificationQuestionariesInit,
	setVerificationTestQuestionaries,
} from '../../../../services/verification';
import { ROUTES } from '../../../../types/routes';
import { mapClassnameForMobile } from '../../../../utils';
import { CustomButton } from '../../../common/buttons';
import { APIError } from '../../../common/error';
import { HeaderLayout } from '../../../common/header';
import { TVerificationSendQuestion } from '../../../../types/types';
import { selectAccessToken } from '../../../../services/auth';
import { selectTheme } from '../../../../services/root';
import { CustomCheckbox } from '../../../common/checkboxes';

const VerificationQuestionariesTestLayout = () => {
	const theme = useAppSelector(selectTheme);
	const accessToken = useAppSelector(selectAccessToken);
	const questionaries = useAppSelector(selectVerificationQuestionaries);
	const currentQuestion = useAppSelector(selectVerificationCurrentQuestion);
	const testQuestionaries = useAppSelector(selectVerificationTestQuestionaries);

	const [isChecked, setIsChecked] = useState<boolean[]>([false, false, false]);
	const [isCheckBoxError, setIsError] = useState<boolean>(false);

	const { t } = useTranslation();
	const buttonRef = useRef(null);
	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	useEffect(() => {
		if (questionaries === undefined) {
			navigate(ROUTES.VERIFICATION.QUESTIONARY.INDEX);
		}
	}, []);

	const handleCheckBoxChange = (
		e: React.ChangeEvent<HTMLInputElement>,
		tempIdx: number
	) => {
		setIsError(false);
		setIsChecked(
			isChecked?.map((value, idx) => {
				if (idx === tempIdx) {
					value = e.target.checked;
				} else {
					value = false;
				}
				return value;
			})
		);
	};

	const { sendVerificationQuestionaries, isPending, isError, error } =
		useSendVerificationQuestionaries(navigate, dispatch);

    useEffect(() => {
        if (testQuestionaries?.length === questionaries?.questions?.length) {
            sendVerificationQuestionaries({
                accessToken: accessToken as string,
                dispatch: dispatch,
                questions: testQuestionaries,
            });
        }
    },[currentQuestion, testQuestionaries])

	const handleBackClick = () => {
		dispatch(setVerificationQuestionariesInit());
		navigate(ROUTES.VERIFICATION.QUESTIONARY.INDEX);
	};

	const handleSubmit = () => {
		setIsError(false);
		if (questionaries) {
			if (isChecked.find((value) => value) === undefined) {
				setIsError(true);
                return;
			}
			if (currentQuestion <= questionaries?.questions?.length) {
				dispatch(
					setVerificationTestQuestionaries({
						questionId: questionaries?.questions[currentQuestion]
							?.questionId as string,
						selectedAnswers: [
							questionaries?.questions[currentQuestion]?.answers[
								isChecked?.indexOf(
									isChecked.find((value) => value) as boolean
								)
							]?.answerId,
						],
					} as TVerificationSendQuestion)
				);
				if (currentQuestion + 1 != questionaries?.questions?.length) {
					dispatch(setVerificationCurrentQuestion(currentQuestion + 1));
					setIsError(false);
					setIsChecked([false, false, false]);
				}
			}
		}
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout isFunctionalHeader handleBackClick={handleBackClick} />
				<div className={mapClassnameForMobile('walletLayout')}>
					<div className="innerContainer">
						<h1 className={'innerContainerMainText'}>
							{t('question')
								.replace('{current}', String(currentQuestion + 1))
								.replace(
									'{total}',
									String(questionaries?.questions?.length)
								)}
						</h1>
					</div>
				</div>
			</div>
			<div className="depositButtonsBottomContainer">
				<h1 className={'questionHeaderText'}>
					{questionaries?.questions[currentQuestion].questionValue
						.split('. ')[1]
						.replace(':', '?')}
				</h1>
				<div className="answersContainer">
					{questionaries?.questions[currentQuestion]?.answers?.map(
						(answer, idx) => (
							<div className="answerContainer" key={idx}>
								<CustomCheckbox
									isError={isCheckBoxError || isError}
									className={'answerCheckBox'}
									sx={{
										'&.Mui-checked': {
											color: '#00BEB4 !important',
										},
										color:
											theme === 'dark'
												? '#303030 !important'
												: '#EBECEE !important',
										borderRadius: '50% !important',
									}}
									checked={isChecked[idx]}
									onChange={(e) => handleCheckBoxChange(e, idx)}
								/>
								<h1
									className={
										isCheckBoxError || isError
											? 'answerErrorText'
											: 'answerText'
									}
								>
									{answer.answerValue}
								</h1>
							</div>
						)
					)}
				</div>

				<APIError isError={isError} error={error} />
				<CustomButton
					isLoading={isPending}
					buttonRef={buttonRef}
					text={t('quiz_continue') as string}
					type={'submit'}
					onClick={handleSubmit}
					className={'confirmFullWidthButton'}
				/>
			</div>
		</div>
	);
};

export default VerificationQuestionariesTestLayout;

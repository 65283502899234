import SumsubWebSdk from '@sumsub/websdk-react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectLocale } from '../../../services/root';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { useGetSumSubStatus } from '../../../data/mutations/verification';
import { selectVerificationSumSubAccessToken } from '../../../services/verification';
import { useEffect } from 'react';
import { selectAccessToken } from '../../../services/auth';
import { mapClassnameForMobile } from '../../../utils';
import { HeaderLayout } from '../../common/header';

const VerificationSumSubLayout = () => {
	const accessToken = useAppSelector(selectAccessToken);
	const sumSubAccessToken = useAppSelector(selectVerificationSumSubAccessToken);
	const locale = useAppSelector(selectLocale);

	const dispatch = useAppDispatch();
	const navigate = useCustomNavigate();

	const { getSumSubStatus } = useGetSumSubStatus(dispatch);

	useEffect(() => {
		getSumSubStatus({
			accessToken: accessToken as string,
			dispatch: dispatch,
		});
	}, []);

	//eslint-disable-next-line
	const sumSubMessageHandler = (type: string, payload: any) => {
		if (
			type === 'idCheck.onApplicantStatusChanged' &&
			payload?.reviewResult &&
			payload?.reviewResult?.reviewAnswer === 'GREEN'
		) {
			navigate(ROUTES.WALLET.INDEX);
		}
	};

	const expirationHandler = () => {
		console.log('');
	};

	const handleBackClick = () => {
		navigate(ROUTES.WALLET.INDEX);
	};

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<HeaderLayout isFunctionalHeader handleBackClick={handleBackClick} />
				<div className={mapClassnameForMobile('walletLayout')}>
					{sumSubAccessToken != '' && (
						<SumsubWebSdk
							config={{
								lang: locale,
							}}
							accessToken={sumSubAccessToken}
							onMessage={sumSubMessageHandler}
							expirationHandler={expirationHandler}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default VerificationSumSubLayout;

import { useTranslation } from 'react-i18next';
import { CustomButton } from '../../common/buttons';
import { WhaleIcon } from '../../common/icons';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { ROUTES } from '../../../types/routes';
import { selectSumSubStatus, selectUserVerificationInfo } from '../../../services/user';
import { useAppSelector } from '../../../hooks';
import { selectRegistrationDictionary } from '../../../services/dictionaries';

const WalletConfirmIdentity = () => {
	const verificationInfo = useAppSelector(selectUserVerificationInfo);
	const registrationDictionary = useAppSelector(selectRegistrationDictionary);
	const sumSubStatus = useAppSelector(selectSumSubStatus);

	const { t } = useTranslation();
	const navigate = useCustomNavigate();

	const mapVerificationStepIdToNavigate = () => {
		switch (verificationInfo) {
			case 'eula':
				return ROUTES.VERIFICATION.INDEX;
			case 'profile':
				return ROUTES.VERIFICATION.INDEX;
			case 'questionaries':
				return ROUTES.VERIFICATION.QUESTIONARY.INDEX;
			case 'sumsub':
				return ROUTES.VERIFICATION.SUMSUB;
			default:
				return '';
		}
	};

	const mapVerificationStepIdToTranslKey = () => {
		switch (verificationInfo) {
			case 'eula':
				return t('wallet_confirmYourIdentity');
			case 'profile':
				return t('wallet_confirmYourIdentity');
			case 'questionaries':
				return t('wallet_pleaseTakeBlockchainTest');
			case 'sumsub':
				return registrationDictionary?.dictionary?.statusSumSub?.find(
					(status) => status.statusId === sumSubStatus
				)?.statusName;
			default:
				return '';
		}
	};

	const handleSubmit = () => {
		navigate(mapVerificationStepIdToNavigate());
	};

	return (
		<div className="walletConfirmIdentityContainer">
			<WhaleIcon className="walletConfirmIdentityWhale" />
			<h1 className="walletConfirmIdentityHeader">
				{t('wallet_confirmYourIdentity')}
			</h1>
			<h1 className="walletConfirmIdentityBottom">
				{mapVerificationStepIdToTranslKey()}
			</h1>
			<CustomButton
				className="walletConfirmIdentityButton"
				onClick={handleSubmit}
				text={t('wallet_getIdentityVerification') as string}
			/>
		</div>
	);
};

export default WalletConfirmIdentity;

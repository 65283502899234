import { createSlice } from '@reduxjs/toolkit';
import { TRootState } from '../../store';
import { TUserInfo, TVerificationGetQuiestionariesResponse, TVerificationSendQuestion } from '../../types/types';

export const selectVerificationIsPageInited = (state: TRootState) =>
    state.verification.isVerificationPageInited;

export const selectVerificationStepId = (state: TRootState) => state.verification.stepId;

export const selectVerificationUserInfo = (state: TRootState) =>
    state.verification.userInfo;

export const selectVerificationFirstName = (state: TRootState) =>
    state.verification.firstName;

export const selectVerificationLastName = (state: TRootState) =>
    state.verification.lastName;

export const selectVerificationMiddleName = (state: TRootState) =>
    state.verification.middleName;

export const selectVerificationCitizenship = (state: TRootState) =>
    state.verification.citizenship;

export const selectVerificationDateOfBirth = (state: TRootState) =>
    state.verification.dateOfBirth;

export const selectVerificationEula = (state: TRootState) =>
    state.verification.eula;

export const selectVerificationQuestionaries = (state: TRootState) =>
    state.verification.questionaries;

export const selectVerificationIsTestSuccess = (state: TRootState) =>
    state.verification.isTestSuccess;

export const selectVerificationTestQuestionaries = (state: TRootState) =>
    state.verification.testQuestionaries;

export const selectVerificationCurrentQuestion = (state: TRootState) =>
    state.verification.currentQuestion;

export const selectVerificationSumSubAccessToken = (state: TRootState) =>
    state.verification.sumSubAccessToken;

interface IVerificationState {
    isVerificationPageInited: boolean;
    stepId: string;
    userInfo: TUserInfo[] | undefined;
    firstName: string;
    lastName: string;
    middleName: string;
    citizenship: {
        code: string;
        imgUrl: string;
    } | undefined;
    dateOfBirth: {
        shortDate: string;
        fullDate: string;
    } | undefined;
    eula: unknown;
    questionaries: TVerificationGetQuiestionariesResponse | undefined;
    testQuestionaries: TVerificationSendQuestion[];
    currentQuestion: number;
    isTestSuccess: boolean;
    sumSubAccessToken: string;
}

const initialState: IVerificationState = {
    stepId: '',
    userInfo: undefined,
    isVerificationPageInited: false,
    firstName: '',
    lastName: '',
    middleName: '',
    citizenship: undefined,
    dateOfBirth: undefined,
    eula: undefined,
    questionaries: undefined,
    testQuestionaries: [],
    currentQuestion: 0,
    isTestSuccess: false,
    sumSubAccessToken: ''
};

export const verificationSlice = createSlice({
    name: 'verification',
    initialState,
    reducers: {
        setVerificationIsPageInited(state, action) {
            state.isVerificationPageInited = action.payload;
        },
        setVerificationQuestionaries(state, action) {
            state.questionaries = action.payload;
        },
        setVerificationTestQuestionaries(state, action) {
            state.testQuestionaries.push(action.payload)
        },
        setVerificationCurrentQuestion(state, action) {
            state.currentQuestion = action.payload;
        },
        setVerificationIsTestSucesss(state, action) {
            state.isTestSuccess = action.payload;
        },
        setVerificationEula(state, action) {
            state.eula = action.payload;
        },
        setVerificationStepId(state, action) {
            state.stepId = action.payload;
        },
        setVerificationUserInfo(state, action) {
            state.userInfo = action.payload;
        },
        setVerificationFirstName(state, action) {
            state.firstName = action.payload;
        },
        setVerificationLastName(state, action) {
            state.lastName = action.payload;
        },
        setVerificationMiddleName(state, action) {
            state.middleName = action.payload;
        },
        setVerificationCitizenship(state, action) {
            state.citizenship = action.payload;
        },
        setVerificationDateOfBirth(state, action) {
            state.dateOfBirth = action.payload;
        },
        setVerificationSumSubAccessToken(state, action) {
            state.sumSubAccessToken = action.payload;
        },
        setVerificationQuestionariesInit(state) {
            state.testQuestionaries = [];
            state.isTestSuccess = false;
            state.currentQuestion = 0
        },
        setRegistrationInit(state) {
            state.isVerificationPageInited = false;
            state.stepId = '';
            state.userInfo = undefined;
            state.firstName = ''
            state.lastName = ''
            state.middleName = ''
            state.dateOfBirth = undefined
            state.citizenship = undefined
            state.questionaries = undefined;
            state.eula = undefined
            state.isTestSuccess = false
            state.sumSubAccessToken = ''
            state.testQuestionaries = []
            state.currentQuestion = 0
        },
    },
});

export const {
    setRegistrationInit,
    setVerificationIsPageInited,
    setVerificationStepId,
    setVerificationUserInfo,
    setVerificationCitizenship,
    setVerificationDateOfBirth,
    setVerificationFirstName,
    setVerificationLastName,
    setVerificationMiddleName,
    setVerificationEula,
    setVerificationQuestionaries,
    setVerificationIsTestSucesss,
    setVerificationCurrentQuestion,
    setVerificationQuestionariesInit,
    setVerificationTestQuestionaries,
    setVerificationSumSubAccessToken
} = verificationSlice.actions;

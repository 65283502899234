import { API_ROUTES } from '../../types/api.routes';
import {
	TDefaultBody,
	TVerificationSendEulaCheckBoxesBody,
	TVerificationSendProfileBody,
	TVerificationSendQuiestionariesBody,
} from '../../types/types';
import { mapResponseError } from '../../utils';
import { customFetch } from '../../services';

export async function fetchVerificationInfo({ accessToken, dispatch }: TDefaultBody) {
	const verificationInfoData = await customFetch(
		API_ROUTES.USER.VERIFICATION.INFO.URL,
		{
			method: API_ROUTES.USER.VERIFICATION.INFO.METHOD,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);

	const jsonVerificationInfoData = await verificationInfoData.json();
	if (verificationInfoData.status != 200) {
		mapResponseError(
			verificationInfoData,
			jsonVerificationInfoData,
			undefined,
			dispatch
		);
		throw jsonVerificationInfoData;
	}

	return jsonVerificationInfoData;
}

export async function fetchVerificationGetEula({ accessToken, dispatch }: TDefaultBody) {
	const verificationGetEulaData = await customFetch(
		API_ROUTES.USER.VERIFICATION.GET_EULA.URL,
		{
			method: API_ROUTES.USER.VERIFICATION.GET_EULA.METHOD,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);

	const jsonVerificationGetEulaData = await verificationGetEulaData.json();
	if (verificationGetEulaData.status != 200) {
		mapResponseError(
			verificationGetEulaData,
			jsonVerificationGetEulaData,
			undefined,
			dispatch
		);
		throw jsonVerificationGetEulaData;
	}

	return jsonVerificationGetEulaData;
}

export async function fetchVerificationSendEula({
	accessToken,
	dispatch,
	checkBoxesResult,
}: TVerificationSendEulaCheckBoxesBody) {
	const verificationSendEulaData = await customFetch(
		API_ROUTES.USER.VERIFICATION.SEND_EULA.URL,
		{
			method: API_ROUTES.USER.VERIFICATION.SEND_EULA.METHOD,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify({
				checkBoxesResult: checkBoxesResult,
			}),
		}
	);

	const jsonVerificationSendEulaData = await verificationSendEulaData.json();
	if (verificationSendEulaData.status != 200) {
		mapResponseError(
			verificationSendEulaData,
			jsonVerificationSendEulaData,
			undefined,
			dispatch
		);
		throw jsonVerificationSendEulaData;
	}

	return jsonVerificationSendEulaData;
}

export async function fetchVerificationGetProfile({
	accessToken,
	dispatch,
}: TDefaultBody) {
	const verificationGetProfileData = await customFetch(
		API_ROUTES.USER.VERIFICATION.GET_PROFILE.URL,
		{
			method: API_ROUTES.USER.VERIFICATION.GET_PROFILE.METHOD,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);

	const jsonVerificationGetProfileData = await verificationGetProfileData.json();
	if (verificationGetProfileData.status != 200) {
		mapResponseError(
			verificationGetProfileData,
			jsonVerificationGetProfileData,
			undefined,
			dispatch
		);
		throw jsonVerificationGetProfileData;
	}

	return jsonVerificationGetProfileData;
}

export async function fetchVerificationSendProfile({
	accessToken,
	dispatch,
	firstName,
	lastName,
	middleName,
	citizenship,
	dateOfBirth,
}: TVerificationSendProfileBody) {
	fetchVerificationSendEula({
		accessToken: accessToken,
		dispatch: dispatch,
		checkBoxesResult: undefined
	})

	const verificationSendProfileData = await customFetch(
		API_ROUTES.USER.VERIFICATION.SEND_PROFILE.URL,
		{
			method: API_ROUTES.USER.VERIFICATION.SEND_PROFILE.METHOD,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify({
				firstName: firstName,
				lastName: lastName,
				middleName: middleName,
				citizenship: citizenship,
				dateOfBirth: dateOfBirth,
			}),
		}
	);

	const jsonVerificationSendProfileData = await verificationSendProfileData.json();
	if (verificationSendProfileData.status != 200) {
		mapResponseError(
			verificationSendProfileData,
			jsonVerificationSendProfileData,
			undefined,
			dispatch
		);
		throw jsonVerificationSendProfileData;
	}

	return jsonVerificationSendProfileData;
}

export async function fetchVerificationGetQuestionary({
	accessToken,
	dispatch,
}: TDefaultBody) {
	const verificationGetQuestionaryData = await customFetch(
		API_ROUTES.USER.VERIFICATION.GET_QUESTIONARY.URL,
		{
			method: API_ROUTES.USER.VERIFICATION.GET_QUESTIONARY.METHOD,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);

	const jsonVerificationGetQuestionaryData =
		await verificationGetQuestionaryData.json();
	if (verificationGetQuestionaryData.status != 200) {
		mapResponseError(
			verificationGetQuestionaryData,
			jsonVerificationGetQuestionaryData,
			undefined,
			dispatch
		);
		throw jsonVerificationGetQuestionaryData;
	}

	return jsonVerificationGetQuestionaryData;
}

export async function fetchVerificationSendQuestionary({
	accessToken,
	dispatch,
	questions,
}: TVerificationSendQuiestionariesBody) {
	const verificationSendQuestionaryData = await customFetch(
		API_ROUTES.USER.VERIFICATION.SEND_QUESTIONARY.URL,
		{
			method: API_ROUTES.USER.VERIFICATION.SEND_QUESTIONARY.METHOD,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify({
				questions: questions,
			}),
		}
	);

	const jsonVerificationSendQuestionaryData =
		await verificationSendQuestionaryData.json();
	if (verificationSendQuestionaryData.status != 200) {
		mapResponseError(
			verificationSendQuestionaryData,
			jsonVerificationSendQuestionaryData,
			undefined,
			dispatch
		);
		throw jsonVerificationSendQuestionaryData;
	}

	return jsonVerificationSendQuestionaryData;
}

export async function fetchVerificationSumSub({ accessToken, dispatch }: TDefaultBody) {
	const verificationSumSubData = await customFetch(
		API_ROUTES.USER.VERIFICATION.SUMSUB.URL,
		{
			method: API_ROUTES.USER.VERIFICATION.SUMSUB.METHOD,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
		}
	);

	const jsonVerificationSumSubData = await verificationSumSubData.json();
	if (verificationSumSubData.status != 200) {
		mapResponseError(
			verificationSumSubData,
			jsonVerificationSumSubData,
			undefined,
			dispatch
		);
		throw jsonVerificationSumSubData;
	}

	return jsonVerificationSumSubData;
}

import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
	selectVerificationIsTestSuccess,
	setVerificationQuestionariesInit,
} from '../../../../services/verification';
import { mapClassnameForMobile } from '../../../../utils';
import { CustomButton } from '../../../common/buttons';
import { WhaleIcon } from '../../../common/icons';
import { useTranslation } from 'react-i18next';
import { ROUTES } from '../../../../types/routes';
import { useCustomNavigate } from '../../../../hooks/useCustomNavigate';

const VerificationQuestionariesSummaryLayout = () => {
	const isTestSuccess = useAppSelector(selectVerificationIsTestSuccess);

	const buttonRef = useRef(null);
	const { t } = useTranslation();
	const navigate = useCustomNavigate();
	const dispatch = useAppDispatch();

	const handleSubmit = () => {
		dispatch(setVerificationQuestionariesInit());
		navigate(ROUTES.VERIFICATION.QUESTIONARY.INDEX);
	};

	useEffect(() => {
		if (isTestSuccess) {
			setTimeout(() => {
				navigate(ROUTES.WALLET.INDEX);
			}, 5000);
		}
	}, []);

	if (!isTestSuccess) {
		return (
			<div className={'component'}>
				<div className={'innerComponent'}>
					<div className={mapClassnameForMobile('walletLayout', true)}>
						<div className="innerContainer">
							<WhaleIcon className="walletConfirmIdentityWhale" />
							<h1 className="verificationQuestionariesTextHeader">
								{t('quiz_testFailed')}
							</h1>
							<h1 className="verificationQuestionariesTextBottom">
								{t('quiz_failedInfo')}
							</h1>
						</div>
					</div>
				</div>
				<div className="depositButtonsBottomContainer">
					<CustomButton
						buttonRef={buttonRef}
						text={t('quiz_again') as string}
						type={'submit'}
						onClick={handleSubmit}
						className={'confirmFullWidthButton'}
					/>
				</div>
			</div>
		);
	}

	return (
		<div className={'component'}>
			<div className={'innerComponent'}>
				<div className={mapClassnameForMobile('walletLayout', true)}>
					<div className="innerContainer">
						<WhaleIcon className="walletConfirmIdentityWhale" />
						<h1 className="verificationQuestionariesTextHeader">
							{t('quiz_passed')}
						</h1>
						<h1 className="verificationQuestionariesTextBottom">
							{t('quiz_registrationCompleted')}
						</h1>
					</div>
				</div>
			</div>
		</div>
	);
};

export default VerificationQuestionariesSummaryLayout;

import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../types/titles';
import VerificationQuestionariesLayout from '../../../components/verification/questionaries';

const QuestionariesVerification = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.VERIFICATION.QUESTIONARIES.INDEX}</title>
			</Helmet>
			<VerificationQuestionariesLayout />
		</>
	);
};

export default QuestionariesVerification;

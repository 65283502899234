import { Helmet } from 'react-helmet-async';
import { TITLES } from '../../../../types/titles';
import VerificationQuestionariesTestLayout from '../../../../components/verification/questionaries/test';

const QuestionariesTestVerification = () => {
	return (
		<>
			<Helmet>
				<title>{TITLES.VERIFICATION.QUESTIONARIES.TEST}</title>
			</Helmet>
			<VerificationQuestionariesTestLayout />
		</>
	);
};

export default QuestionariesTestVerification;
